const TUS_SERVER_ENDPOINT = `${process.env.TUS_SERVER}/files`;
const SERVER_ENDPOINT = process.env.API_SERVER;

const version = 1;
console.log(`index.ts v${version} was loaded`);

const dev = getDevMode();

type TimeStamp = number;

import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Webcam from '@uppy/webcam';
import Tus from '@uppy/tus';
import exifr from 'exifr';

import { formatDate } from './format-date';

const deviceName = getDeviceName();

function getDeviceName() {
  let name = localStorage.getItem('device-name');
  if (!name) {
    name = Math.random().toString(36).substr(2, 6);
    localStorage.setItem('device-name', name);
  }
  return name;
}

const uppy = Uppy<Uppy.StrictTypes>({
  debug: true,
  autoProceed: false,
  restrictions: {
    maxFileSize: 1_000_000_000,
    maxNumberOfFiles: 15,
    minNumberOfFiles: 0,
    allowedFileTypes: ['image/*', 'video/*'],
  },
})
  .use(Dashboard, {
    // trigger: '.UppyModalOpenerBtn',
    inline: true,
    target: '#uppy-container',
    replaceTargetContent: true,
    showProgressDetails: true,
    note:
      'Images and video only. The creation date will be read and used as the future file name',
    // height: 470,
    metaFields: [
      {
        id: 'caption',
        name: 'Caption',
        placeholder: 'describe what the image is about',
      },
      {
        id: 'created',
        name: 'Created',
        placeholder: 'Will be used as the new file name',
      },
    ],
    browserBackButtonClose: false,
  })
  .use(Webcam, { target: Dashboard })
  .use(Tus, { endpoint: TUS_SERVER_ENDPOINT, limit: 7 });

uppy.setMeta({ deviceName });

uppy.on('file-added', (file) => {
  console.warn('file added', file);

  exifr
    .parse(file.data)
    .then((d) => {
      if (!d) return;
      const date = d.DateTimeOriginal || d.CreateDate || d.ModifyDate;
      if (file.id && date) {
        // @ts-ignore (wrong definitions)
        uppy.setFileMeta(file.id, { created: formatDate(date) });
      }
    })
    .catch((error: Error) => {
      console.warn('Could not parse exif data', { error, file });
    });
});

uppy.on('complete', (result) => {
  console.log('successful files:', result.successful);
  console.log('failed files:', result.failed);
});

/* destination folder selection */

const selectFolder = (document.getElementById('folder') as HTMLInputElement)!;
selectFolder.onblur = (evt: Event) => {
  console.log('ev', evt);
  uppy.setMeta({ folder: selectFolder.value });
};
populateFolderSelectDatalist(selectFolder);

async function populateFolderSelectDatalist(selectFolder: HTMLInputElement) {
  while (selectFolder.firstChild) {
    selectFolder.firstChild.remove();
  }
  const folders = await fetchJson<string[]>(SERVER_ENDPOINT + '/folders').catch(
    (reason: any) => {
      console.warn('Could not fetch folders, reason: ', reason);
      return ['undecided', '2020-07'];
    },
  );
  console.log('Remote propagated these folders:', folders);
  if (!Array.isArray(folders) || folders.length === 0) return;

  const dl = document.createElement('datalist');
  dl.id = 'folder-list';
  selectFolder.setAttribute('list', dl.id);

  for (const folder of folders) {
    const option = document.createElement('option');
    option.value = folder;
    option.textContent = folder;
    dl.appendChild(option);
  }
  selectFolder.appendChild(dl);

  uppy.setMeta({ folder: 'undecided' });
}

async function fetchJson<T>(uri: string): Promise<T> {
  return fetch(uri).then((response) => response.json());
}

/* For debugging */

const input = document.getElementById('dev_select_image') as HTMLInputElement;
if (!dev) input.style.display = 'none';
input?.addEventListener('change', async () => {
  if (!input.files) return;
  for (const file of input.files) {
    addImageFile(file);
  }
});

navigator.serviceWorker.addEventListener('message', (event: MessageEvent) => {
  console.log('Received a message from the service worker', event.data);

  if (dev) appendEventDataToDOM(event.data);

  if (event.data.action === 'share-file' && event.data.value) {
    addImageFile(event.data.value);
  }
});

function addImageFile(file: File) {
  console.log('file', file);

  const fileId: string = uppy.addFile({
    name: file.name,
    type: file.type,
    data: file,
    meta: { created: undefined },
    source: 'local',
  });
  console.log('was given id ', fileId);
}

function appendEventDataToDOM(data: any) {
  const code = document.body.appendChild(document.createElement('code'));
  code.textContent = JSON.stringify(data);

  if (data.action === 'share-file' && data.value) {
    appendImageFileToDOM(data.value, code);
  }
}

function appendImageFileToDOM(file: File, code: HTMLElement) {
  code.textContent =
    'Added: ' +
    JSON.stringify({ name: file.name, size: file.size, type: file.type });

  // Update the UI with the data that has been shared to it.
  const img = document.body.appendChild(document.createElement('img'));
  img.style.maxWidth = '100vw';
  img.style.maxHeight = '50vh';
  img.src = URL.createObjectURL(file);
}

console.log('index.ts was loaded');

function getDevMode() {
  let dev = false;
  try {
    dev = JSON.parse(localStorage.getItem('dev-mode') || '') === true;
  } catch (ex) {}

  console.log(`"dev-mode" in localStorage is ${dev}`);
  return dev;
}
